.header {
  position: fixed; 
  top: 0;
  left: 0; 
  right: 0;
  display: grid;
  grid-template-columns: auto 1fr auto; 
  align-items: center;
  padding: 0 20px;
  z-index: 10;
  animation: fadeIn 2s forwards; 
}

.logo {
  width: 150px;
  height: auto;
  padding-top: 40px;
}

.menu {
  text-align: center;
  display: flex;
  align-items: end;
  justify-content: flex-end; 
}

.menuTwo {
  display: flex;
  justify-content: center;
}

.menu-button {
  padding: 8px 16px;
  margin: 0 10px;
  background-color: transparent;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  border: none;
  cursor: pointer;
}

.language-icon {
  cursor: pointer;
  font-size: 20px;
  margin-right: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 50%;
  background-color: white;
  color: red;
  transition: transform 0.3s ease;
}

.language-icon img {
  width: 25px;
  height: 25px;
}

.menu-button:hover {
  color: #D32F2F;
  border-bottom: 1px solid #D32F2F;
}

.language-icon:hover {
  transform: scale(1.2);
}

@media screen and (max-width: 768px) {
  .header {
    grid-template-columns: 1fr; 
  }

  .menuTwo {
    flex-direction: column; 
    padding-top: 10px; 
    justify-content: center; 
  }
}
