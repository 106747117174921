.footer {
    width: 100%;
    height: 150px; 
    background-color:transparent; 
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom:0;
    animation: fadeIn 3s forwards; 
  }
  .w-36{
    width: 30%;
    height: auto;
  }
  