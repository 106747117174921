* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }
  
  .main {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: auto 1fr auto; 
  }
  
  /* .video-container {
    position: relative;
    grid-row: 1 / 2; 
    background-color: transparent; 
  } */
  
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 0;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  .main-content {
    display: grid;
    place-items: center;
    grid-row: 2 / 3; /* Content spans the second row */
    z-index: 2;
  }
  
  .footer-container {
    grid-row: 3 / 4; /* Footer spans the third row */
    z-index: 0;
  }
  