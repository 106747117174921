.searchIcon {
    transition: transform 0.3s ease, opacity 0.5s ease;
    opacity: 1;
  }
  
  .searchIcon:hover {
    opacity: 0.8;
  }
  
  .searchIcon.selected {
    transform: scale(1.2);
    opacity: 1;
    transition: transform 0.3s ease, opacity 0.5s ease;
  }

  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  
  @media (max-width: 768px) {
    .container {
      grid-template-columns: 1fr;
    }
  }

  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
